
import {setup, Vue} from 'vue-class-component'
import {dialogs, SortingDefault} from '@/composables/messages/dialogs'
import {LimitPageVariants, LimitPageDefault} from '@/composables/constants'
import {Sorting} from '@/services/types'
import {positiveInt} from '@/composables/mask'

export default class Card extends Vue {

  showOptions = false
  page = 1
  limit = LimitPageDefault.value
  sorting: Sorting = SortingDefault

   created(): void {
    this.clear()
  }

  ctx = setup(() => {
    return {...dialogs(), LimitPageVariants}
  })


  /**
   * Общая часть
   */

  // триггер на обновление таблицы для новой страницы
  async changePage(): Promise<void> {
    this.page = positiveInt(this.page)
    await this.fetch()
  }

  // Применить фильтры
  async apply() {
    this.page = 1
    await this.fetch()
  }

  // Сбросить фильтры до дефолта
  async clear() {
    this.ctx.reset()
    this.cancel()
    await this.fetch()
  }

  // Отменить введенные фильтры
  cancel() {
    this.page = this.ctx.meta.offset / this.ctx.meta.limit + 1
    this.limit = this.ctx.meta.limit
    this.sorting = this.ctx.sorting
    this.close()
  }

  close() {
    this.showOptions = false
  }

  scroll(){
    document.getElementById('table')?.scrollTo({top: 0, behavior: 'smooth'})
  }

  async fetch(){
    this.ctx.setLimit(this.limit)
    this.ctx.setPage(this.page)
    this.ctx.setSorting(this.sorting)

    await this.ctx.fetch()
    this.close()
    this.scroll()
  }
}
